<template>
  <LSection :use-margin="false">
    <template #body>
      <div class="aboutChatbot">
        <div class="heading1">
          Introducing the QT Strategy Chatbot
          <hr class="gradientLine" />
        </div>
        <LSection :use-margin="false">
          <template #body>
            <div class="text-gray-700">
              <section
                class="k1:text-center gap-12 h8:grid-cols-2 grid w-full k1:p-8 p-4 bg-slate-100"
              >
                <p class="">
                  Curious about the world of quantum technology strategies? The
                  QT Strategy Bot is here to make it simple and engaging. Each
                  of the 62 countries featured in our bot has a unique approach
                  to quantum development—whether in research, industry
                  partnerships, or government policy.
                </p>
                <p class="">
                  Our user-friendly chatbot interface allows you to click on and
                  access specific documents that match your interests, making it
                  easier than ever to stay informed. Ready to explore? Dive into
                  the global quantum landscape with the QT Strategy Bot today!
                  With the QT Strategy Bot, you can:
                </p>
              </section>
              <ul
                class="mt-10 bg-slate-100 k1:p-8 p-4 grid h8:grid-cols-3 gap-8"
              >
                <li class="bg-white shadow-slate-200 shadow-xl p-5">
                  <span class="leading-none font-bold gradientText w-fit block"
                    >I) Explore Comprehensive Quantum Strategies
                  </span>
                  View documents from countries like the U.S, Australia or the
                  European Union, and more to understand their quantum
                  priorities.
                </li>
                <li class="bg-white shadow-slate-200 shadow-xl p-5">
                  <span class="leading-none font-bold gradientText w-fit block"
                    >II) Discover Key Initiatives
                  </span>
                  See how different regions approach challenges like
                  cybersecurity, quantum computing, and workforce development.
                </li>
                <li class="bg-white shadow-slate-200 shadow-xl p-5">
                  <span class="leading-none font-bold gradientText w-fit block"
                    >III) Stay Ahead of Global Trends
                  </span>
                  Easily compare strategies to identify collaborative
                  opportunities or emerging trends shaping quantum technology's
                  future.
                </li>
              </ul>
            </div>
          </template>
        </LSection>
        <video controls="" class="mt-10">
          <source
            src="/videos/QT%20Strategy%20Chatbot%20v.4.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>
    </template>
  </LSection>
</template>

<script>
import LSection from "@/components/layout/LSection.vue";
import { onMounted } from "vue";

export default {
  components: {
    // JobMap,
    LSection,
  },
  setup() {
    const setHeadingColor = () => {
      const heading = document.getElementById("analysisHeader");
      // if (window.scrollY < 1340) {

      if (heading !== null && heading?.classList) {
        heading.classList.add("text-white");
        heading.classList.remove("text-black");
      }
      // } else {
      //   heading.classList.add('text-black')
      //   heading.classList.remove('text-white')
      // }
    };
    onMounted(() => {
      window.addEventListener("scroll", setHeadingColor);
    });
    return {};
  },
};
</script>

<style scoped></style>
